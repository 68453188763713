import React, { useState, useEffect } from "react"

import axios from "axios"

import Layout from "../components/common/layout/layout"

const InternalServerErrorPage = () => {
  const [headerConfigs, setHeaderConfigs] = useState(null)
  const [footerConfigs, setFooterConfigs] = useState(null)

  useEffect(() => {
    axios
      .get(`https://listener-strapy.thursday.social/header-configurations`)
      .then(response => {
        const responseData = response.data

        setHeaderConfigs(responseData)
      })

    axios
      .get(`https://listener-strapy.thursday.social/footer-configurations`)
      .then(response => {
        const responseData = response.data

        setFooterConfigs(responseData)
      })
  }, [])

  return (
    <Layout headerData={headerConfigs} footerData={footerConfigs}>
      <section className="hero-section">
        <div className="content-layer">
          <h1>Oops, something went wrong</h1>
        </div>
      </section>
    </Layout>
  )
}

export default InternalServerErrorPage
